// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Actions
// ----------------------------------------------------------------------------
export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'
export const UPDATE_MEDIA_STATE = 'UPDATE_MEDIA_STATE'
export const UPDATE_SCREEN_SIZE_STATE = 'UPDATE_SCREEN_SIZE_STATE'
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE'

export const UPDATE_GRID_OVERLAY_VIEW_STATE = 'UPDATE_GRID_OVERLAY_VIEW_STATE'
export const UPDATE_GRID_OVERLAY_STATE = 'UPDATE_GRID_OVERLAY_STATE'

export const UPDATE_DAY_NIGHT_MODE = 'UPDATE_DAY_NIGHT_MODE'

export const UPDATE_COLOR = 'UPDATE_COLOR'
export const UPDATE_COLOR_RANDOM_SWITCH = 'UPDATE_COLOR_RANDOM_SWITCH'

export const UPDATE_DRAWER = 'UPDATE_DRAWER'
export const UPDATE_DRAWER_TITLE = 'UPDATE_DRAWER_TITLE'
export const UPDATE_DRAWER_ABSTRACT = 'UPDATE_DRAWER_ABSTRACT'

export const UPDATE_TYPE = 'UPDATE_TYPE'

export const UPDATE_USER_SYSTEM_STATE = 'UPDATE_USER_SYSTEM_STATE'

export const SET_DATA_BEFORE_NAVIGATION = 'SET_DATA_BEFORE_NAVIGATION'

export const RESET_DATA_BEFORE_NAVIGATION = 'RESET_DATA_BEFORE_NAVIGATION'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------ Action creators
// ----------------------------------------------------------------------------
/** Increase */
export const increase = (initObject) => ({
  type: INCREASE,
  payload: initObject,
})

/** Decrease */
export const decrease = (initObject) => ({
  type: DECREASE,
  payload: initObject,
})

/** updateMediaState */
export const updateMediaState = (initObject) => ({
  type: UPDATE_MEDIA_STATE,
  payload: initObject,
})

/** updateScreenSizeState */
export const updateScreenSizeState = (initObject) => ({
  type: UPDATE_SCREEN_SIZE_STATE,
  payload: initObject,
})

/** updateHeaderState */
export const updateHeaderState = (initObject) => ({
  type: UPDATE_HEADER_STATE,
  payload: initObject,
})

/** updateGridOverlayViewState */
export const updateGridOverlayViewState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_VIEW_STATE,
  payload: initObject,
})

/** updateGridOverlayState */
export const updateGridOverlayState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_STATE,
  payload: initObject,
})

/** updateDayNight */
export const updateDayNightMode = (initObject) => ({
  type: UPDATE_DAY_NIGHT_MODE,
  payload: initObject,
})

/** updateColor */
export const updateColor = (initObject) => ({
  type: UPDATE_COLOR,
  payload: initObject,
})

/** updateColorRandomSwitch */
export const updateColorRandomSwitch = (initObject) => ({
  type: UPDATE_COLOR_RANDOM_SWITCH,
  payload: initObject,
})

/** updateDrawer */
export const updateDrawer = (initObject) => ({
  type: UPDATE_DRAWER,
  payload: initObject,
})

/** updateDrawerTitle */
export const updateDrawerTitle = (initObject) => ({
  type: UPDATE_DRAWER_TITLE,
  payload: initObject,
})

/** updateDrawerAbstract */
export const updateDrawerAbstract = (initObject) => ({
  type: UPDATE_DRAWER_ABSTRACT,
  payload: initObject,
})

/** updateType */
export const updateType = (initObject) => ({
  type: UPDATE_TYPE,
  payload: initObject,
})

/** UPDATE_USER_SYSTEM_STATE */
export const updateUserSystemState = (initObject) => ({
  type: UPDATE_USER_SYSTEM_STATE,
  payload: initObject,
})

/** SET_DATA_BEFORE_NAVIGATION */
export const setDataBeforeNavigation = (initObject) => ({
  type: SET_DATA_BEFORE_NAVIGATION,
  payload: initObject,
})

/** RESET_DATA_BEFORE_NAVIGATION */
export const resetDataBeforeNavigation = (initObject) => ({
  type: RESET_DATA_BEFORE_NAVIGATION,
  payload: initObject,
})
